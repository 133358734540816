import { styled, mq, fonts } from '../../styles'

const P = styled.div(
  {
    textAlign: 'center',
  },
  (props) => ({
    fontFamily: fonts.monosten.light,
  }),
  mq({
    fontSize: ['.9em'],
    lineHeight: [1.4],
  }),
)

export default P
