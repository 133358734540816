import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import Logo from '../images/logo.png'
import { colors, fonts, mq, spaces, styled, typography } from '../styles'
import Caption from './Caption'
import ImageCarousel from './ImageCarousel'
import SanityFluidImage from './SanityFluidImage'
import SpaceBreak from './SpaceBreak'
import VideoPlayer from './VideoPlayer'
import WaitlistButton from './WaitlistButton'
import config from '../config'
import HowOdaFeelsSection from './sections/HowOdaFeelsSection'
import { useState } from 'react'
import { useEffect } from 'react'

const { sanityImages } = config

const FrontMatter = () => {
  return (
    <>
      <HeroSection />
      <Divider />
      <MembershipSection />
      <Divider />
      <ArchivePreviewSection />
      <Divider />
      <HowItFeelsSection />
      <Divider />
      <SystemSection />
      <GallerySection />
      <WaitlistSection />
      <HowOdaFeelsSection />
    </>
  )
}

export const Section = styled.div(
  mq({
    paddingTop: spaces.large,
    paddingBottom: spaces.large,
  }),
)

const Divider = styled.div(
  mq({
    borderBottom: '1px solid black',
  }),
)

export const SectionContent = styled.div(
  mq({
    maxWidth: 1040,
    margin: '0 auto',
    paddingLeft: [17],
    paddingRight: [17],
  }),
)

export const SectionTitle = styled.h1(
  typography.extraLargeSerif,
  mq({
    textAlign: 'center',
    paddingBottom: spaces.medium,
  }),
)

const SectionShopButtonContainer = styled.div(
  mq({
    marginTop: [spaces.small[0], spaces.large[1], spaces.large[2]],
  }),
)

/* Hero Section */

const HeroSection = () => {
  return (
    <HeroSectionContainer>
      <SectionContent>
        <SectionTitle>
          Listen.
          <SpaceBreak.TabletDown />
          Really Listen.
        </SectionTitle>

        <HeroLogoImage src={Logo} />

        <HeroWelcomeText>
          Immersive sound events.
          <SpaceBreak.Desktop />
          Unexpected journeys. Intimate music performances.
          <SpaceBreak.Desktop />
          All broadcast directly to you live with uncanny presence.
          <SpaceBreak.Desktop />
          Rediscover wonder in the world through sound. <br />
        </HeroWelcomeText>

        <HeroMainImage
          aspectRatio={1.5}
          url={sanityImages.hero}
          width={1000}
          sizes={'(max-width: 1000px) 90vw, 1000px'}
        />

        <HeroShopButtonTitle>
          Oda is a membership
          <SpaceBreak.Mobile />
          to live listening.
        </HeroShopButtonTitle>

        <SectionShopButtonContainer>
          <WaitlistButton size="medium" />
        </SectionShopButtonContainer>
      </SectionContent>
    </HeroSectionContainer>
  )
}

const HeroSectionContainer = styled(Section)({
  backgroundColor: '#EDE6D9',
})

const HeroLogoImage = styled.img(
  mq({
    width: [110, 60, 60],
    height: [110, 60, 60],
    margin: '0 auto',
    marginTop: spaces.small.map((n) => -n),
    marginBottom: spaces.medium,
    display: 'block',
  }),
)

const HeroWelcomeText = styled.p(
  typography.mediumSerif,
  mq({
    textAlign: 'center',
    margin: 0,
    marginTop: '-8px',
  }),
)

const HeroMainImage = styled(SanityFluidImage)(
  mq({
    marginTop: spaces.medium,
    maxWidth: '100%',
  }),
)

const HeroShopButtonTitle = styled.div(
  typography.mediumSans,
  mq({
    display: ['none', 'none'],
    textAlign: 'center',
    marginTop: spaces.medium,
  }),
)

/* Membership Section */

const MembershipSection = () => {
  return (
    <Section>
      <SectionContent>
        <SectionTitle>
          A Seasonal Membership
          <br />
          for Live Listening
        </SectionTitle>

        <MembershipSectionImage
          aspectRatio={1.4444}
          url={sanityImages.membership}
          width={386}
          sizes={'(max-width: 800px) 100vw, 385px'}
        />

        <MembershipSectionText>
          Oda invites you to experience wonder and connection through sound.
          Each season you’ll hear continuous soundscapes broadcast live that
          transport you to distant cities and remote landscapes around the
          globe.
          <br />
          <br />
          Each week, you’ll hear from a curated roster of artists who will
          create a unique performance exclusively for Oda members. In singular
          moments or journeys that unfold over weeks, listeners connect directly
          with each artist as they broadcast live from their corner of the
          world.
        </MembershipSectionText>
      </SectionContent>
    </Section>
  )
}

const MembershipSectionImage = styled(SanityFluidImage)(
  mq({
    maxWidth: '100%',
    width: 385,
    margin: '15px auto',
    marginBottom: spaces.large,
  }),
)

const MembershipSectionText = styled.div(typography.smallSerif, mq({}))

/* Program Section */

const ProgramSection = () => {
  return (
    <ProgramSectionContainer>
      <SectionContent>
        <SectionTitle>
          Summer Season<ProgramSectionTitleDash> – </ProgramSectionTitleDash>{' '}
          2021
        </SectionTitle>

        <ProgramSectionImageContainer>
          <ProgramSectionImage
            aspectRatio={1.5173}
            url={sanityImages.summerProgram}
            width={785}
            sizes={'(max-width: 1000px) 90vw, 784px'}
          />
          <Caption>Photo by Mark Borthwick</Caption>
        </ProgramSectionImageContainer>

        <ProgramSectionText>
          Sound has the power to transport us — to distant lands and into deeper
          mental states. Join us this summer as we put our collective ear up to
          the world, journeying to remote Swedish villages and bustling Japanese
          cities, buzzing secret gardens and idyllic animal sanctuaries,
          rhythmic coastlines and iconic recording studios.
          <br />
          <br />
          Together, we’ll rediscover the magic of the everyday through
          uninterrupted broadcasts from the most unexpected places and intimate
          live performances.
        </ProgramSectionText>
      </SectionContent>

      <SectionContent>
        <ProgramSectionArtistsContainer>
          <ProgramSectionArtistsIntroText>
            Our Summer Season Features
          </ProgramSectionArtistsIntroText>
          <ProgramSectionArtistNames>
            {renderProgramSectionArtistNames([
              'Beverly Glenn-Copeland ',
              'Laraaji',
              'Julianna Barwick',
              'Julia Holter',
              'Arooj Aftab',
              'Mary Lattimore',
              'Mark Borthwick',
              'Yoshimi (Boredoms, 00I00)',
              'Elizabeth King',
              'Howard Grimes',
              'Archie “Hubbie” Turner',
              'Earl the Pearl',
              'Josephine Foster',
              'Lonnie Holley',
              'Japanese Temples',
              'Sound Sculptures in Upstate New York ',
              'Birds and Bells in the Sonoran Desert',
              'Swedish Midsommar Solstice',
              'The Kaaterskill Animal Sanctuary',
            ])}
          </ProgramSectionArtistNames>

          <ProgramSectionArtistsBottomText>
            And more...
          </ProgramSectionArtistsBottomText>
        </ProgramSectionArtistsContainer>
      </SectionContent>

      <SectionShopButtonContainer>
        <WaitlistButton size="medium" colorScheme="dark" />
      </SectionShopButtonContainer>
    </ProgramSectionContainer>
  )
}

const ProgramSectionContainer = styled(Section)(
  mq({
    borderBottom: 0,
  }),
)

const ProgramSectionTitleDash = styled.span(
  mq({
    display: ['inline', 'none', 'none'],
  }),
)

const ProgramSectionImageContainer = styled.div(
  mq({
    margin: '15px auto',
    marginBottom: spaces.large,
  }),
)

const ProgramSectionImage = styled(SanityFluidImage)(
  mq({
    width: 785,
    maxWidth: '100%',
    margin: '0 auto',
  }),
)

const ProgramSectionText = styled.div(typography.smallSerif, mq({}))

const ProgramSectionArtistsContainer = styled(SectionContent)(
  mq({
    border: '1px solid black',
    borderRadius: [30, 18],
    textAlign: 'center',
    padding: [50, 20],
    paddingBottom: [80, 40],
    width: '1200px',
    maxWidth: '100%',
    marginTop: spaces.large,
    marginBottom: spaces.large,
  }),
)

const ProgramSectionArtistsIntroText = styled.div(typography.smallSans)

const ProgramSectionArtistsTitle = styled.div(
  typography.largeSans,
  mq({
    marginTop: spaces.medium,
    marginBottom: spaces.medium,
  }),
)

const ProgramSectionArtistNamesLabel = styled.div(
  typography.smallSans,
  mq({
    marginBottom: spaces.small,
  }),
)

const ProgramSectionArtistNames = styled.div(
  typography.mediumSans,
  mq({
    lineHeight: ['1.2'],
    margin: '.5em auto',
    marginTop: '1rem',
    marginBottom: '.75em',
  }),
)

const renderProgramSectionArtistNames = (names: string[] = []) => {
  return (names || []).reduce((memo, name, i) => {
    memo.push(
      <ProgramSectionArtistName key={`name-${i}`}>
        {name}
      </ProgramSectionArtistName>,
    )
    if (i !== names.length - 1)
      memo.push(
        <ProgramSectionArtistDot key={`dot-${i}`}>
          {' ● '}
        </ProgramSectionArtistDot>,
      )
    return memo
  }, [] as JSX.Element[])
}

const ProgramSectionArtistName = styled('div')({
  display: 'inline',
})

const ProgramSectionArtistDot = styled('div')({
  margin: '0',
  display: 'inline',
})

const ProgramSectionArtistsBottomText = styled.div(typography.tinyMono)

/* Video Section */

const VideoSection = () => {
  return (
    <VideoContainer>
      <Player
        aspect={1.77777777777}
        id={'b19fb93cc2a5a6dd3edec57def406ab2'}
        muted
        loop
        posterTime={4}
      />
    </VideoContainer>
  )
}

const VideoContainer = styled('div')({
  width: '100%',
})

const Player = styled(VideoPlayer)(
  mq({
    width: ['100%'],
    margin: '0 auto',
    pointerEvents: 'none',
    mixBlendMode: 'multiply',
  }),
)

/* How it feels section */

const HowItFeelsSection = () => {
  return (
    <Section>
      <SectionTitle>How it Feels</SectionTitle>

      <HowItFeelsSectionContent>
        <SanityFluidImage
          aspectRatio={1.77777}
          url={sanityImages.howItFeels}
          width={1000}
          sizes={'(max-width: 1000px) 90vw, 1000px'}
        />

        <Caption>Oda immerses you in sound</Caption>
      </HowItFeelsSectionContent>
    </Section>
  )
}

const HowItFeelsSectionContent = styled(SectionContent)(mq({}))

/* System Section */

const SystemSection = () => {
  return (
    <SystemSectionContainer>
      <SystemSectionTitle>
        How You
        <SpaceBreak.TabletDown />
        Listen
      </SystemSectionTitle>
      <SystemSectionSubtitle>
        Oda is a seasonal subscription to live sound available to stream on The
        Oda System.
      </SystemSectionSubtitle>

      <SystemSectionContent>
        <SystemSectionGroup>
          <SystemSectionGroupImageContainer>
            <SystemSectionGroupImage
              aspectRatio={1}
              url={sanityImages.systemMembership}
              width={400}
              sizes={'(max-width: 800px) 250px, 400px'}
              transparent={true}
            />
          </SystemSectionGroupImageContainer>

          <SystemSectionGroupText>
            <SystemSectionGroupTitle>The Membership</SystemSectionGroupTitle>
            <SystemSectionGroupDescription>
              Each season, we explore a new theme through curated weekly
              performances and field transmissions from around the world –
              always live, always in real time. You’ll join a dedicated
              community of listeners and receive access to artists broadcasting
              directly to your home from theirs. Through expansive soundscapes
              and intimate concerts, you’ll discover a new connection to the
              world through sound.
            </SystemSectionGroupDescription>
          </SystemSectionGroupText>
        </SystemSectionGroup>

        <SystemSectionGroup>
          <SystemSectionGroupImageContainer>
            <SystemSectionGroupImage
              aspectRatio={1}
              url={sanityImages.systemSpeaker}
              width={400}
              sizes={'(max-width: 800px) 250px, 400px'}
              transparent={true}
            />
          </SystemSectionGroupImageContainer>
          <SystemSectionGroupText>
            <SystemSectionGroupTitle>The Oda System</SystemSectionGroupTitle>
            <SystemSectionGroupDescription>
              Our home listening system is designed to create a sonic experience
              that is warm, enveloping, and direct. Our Lighthouse Receiver
              connects you directly to our broadcasts, lighting up when a new
              transmission is ready for your ears. Our handmade speakers —
              constructed elegantly with wood that resonates like a cello — act
              as portals into distant and intimate worlds creating connection
              through immersive sound.
            </SystemSectionGroupDescription>
          </SystemSectionGroupText>
        </SystemSectionGroup>
      </SystemSectionContent>
    </SystemSectionContainer>
  )
}

const SystemSectionContainer = styled(Section)(
  mq({
    borderBottom: 'none',
  }),
)

const SystemSectionTitle = styled(SectionTitle)(
  mq({
    paddingBottom: 0,
  }),
)

const SystemSectionSubtitle = styled.div(
  typography.mediumSerif,
  mq({
    textAlign: 'center',
    width: 1000,
    maxWidth: '100%',
    margin: '0 auto',
    marginBottom: spaces.large,
  }),
)

const SystemSectionContent = styled(SectionContent)(mq({}))

const SystemSectionGroup = styled.div(
  mq({
    width: '1200px',
    margin: '0 auto',
    maxWidth: '100%',
    display: ['flex', 'block'],
    marginBottom: spaces.large,
    ':nth-child(even)': {
      flexDirection: 'row-reverse',
    },
    ':last-child': {
      marginBottom: 0,
    },
  }),
)

const SystemSectionGroupImageContainer = styled.div(
  mq({
    width: ['50%', 'auto'],
  }),
)

const SystemSectionGroupImage = styled(SanityFluidImage)(
  mq({
    width: [400, 250],
    maxWidth: '100%',
    display: 'block',
    margin: '0 auto',
  }),
)

const SystemSectionGroupText = styled.div(
  mq({
    width: ['50%', 'auto'],
  }),
)

const SystemSectionGroupTitle = styled.div(
  typography.smallSans,
  mq({
    textAlign: 'center',
    marginBottom: '1em',
  }),
)

const SystemSectionGroupDescription = styled.div(typography.smallSerif, mq({}))

/* Gallery Section */

const GallerySection = () => {
  return (
    <GallerySectionContainer>
      <ImageCarousel />
    </GallerySectionContainer>
  )
}

const GallerySectionContainer = styled.div(
  mq({
    paddingBottom: spaces.large,
  }),
)

/* Archive Preview Section */

const archiveCards = [
  {
    image: 'angel-bat-dawid.jpg',
    title: 'A Weekend with Angel Bat Dawid',
    location: 'Angel’s home in Chicago, IL',
    text: 'Memories of falling in love with music, spontaneous melodies on her clarinet, songs for the listeners. Spiritual jazz soothsayer Angel Bat Dawid guided us on a journey each new day, live from her home in Chicago’s South Side.',
  },
  {
    image: 'bevery-glenn-copeland.jpg',
    title: 'A Couple Days with Beverly Glenn-Copeland',
    location: 'Glenn’s living room in Nova Scotia, CA',
    text: 'Poems with his long-time collaborator and partner Elizabeth, “Keyboard Fantasies,” laughs, unreleased songs, and spontaneous appearances from their cat Mew Mew. The ever-wonderful Beverly Glenn-Copeland invited us to join him for a couple days at his home in Nova Scotia.',
  },
  {
    image: 'don-bryant.jpg',
    title: 'A Weekend with Don Bryant and Ann Peebles',
    location: 'Memphis, TN',
    text: 'Rain, a collaboration as old as love, the sounds of Memphis soul, and of course records from the 70s. For a rare and special occasion, soul royalty Don Bryant and Ann Peebles shared songs and stories, live from the studio in Memphis, TN.',
  },
  {
    image: 'iceland.jpg',
    title: 'A Journey through the Southern Coast of Iceland',
    location: 'Iceland',
    text: 'The slow dripping sounds of ice melting in a cave, a volcano erupting with lava 900 feet in the sky, a storm passing the Dyrhólaey Lighthouse. Örvar Smárason and his wonderful friends took us to listen to the sounds of Iceland outside all along the southern coast.',
  },
  {
    image: 'kaaterskill.jpg',
    title: 'The Summer at The Kaaterskills Animal Sanctuary',
    location: 'Catskills, NY ',
    text: 'The rhythms of the day as animals rustle and rouse with the sun, and settle and mew as dusk dims and the gentle sounds of sleep fall on the hushed countryside. Our live soundtrack for summer, with the animals in an open air farm in Catskills, NY.',
  },
  {
    image: 'laraaji.jpg',
    title: 'Sunsets with Laraaji',
    location: 'Laraaji’s home in Harlem, NY',
    text: 'A deep cosmic tone baptism, laughter, a zither and hammered dulcimer. We shared countless sunsets with Laraaji, live from his home in Harlem, NY.',
  },
  {
    image: 'marjorie.jpg',
    title: 'A Weekend with Marjorie Eliot',
    location: 'Marjorie’s jazz parlor in Sugar Hill, NY',
    text: 'Words to nourish the soul, an afternoon shared beside her piano, songs and collaboration, always in memory of her sons. Marjorie Eliot welcomed us to join her and her son Rudel Drears, live in her jazz parlor in Sugar Hill, NY. ',
  },
  {
    image: 'nara.jpg',
    title: 'The Wonders of Nara with Tokiko Ihara',
    location: 'Nara, Japan',
    text: 'Moments of tender listening in an ancient temple of Nara, the sounds of Tokiko Ihara’s luminous shō bringing us back to our earthly senses, presence. Tokiko Ihara shared with us the wonders of Nara, live through sound in Yamato, JP. ',
  },
  {
    image: 'oaxaca.jpg',
    title: 'A Journey from Oaxaca to Chiapas in the South of Mexico',
    location: 'Oaxaca and Chiapas, Mexico',
    text: 'Migrating sounds, voices, rattles, wandering from one sound vista to another, with moments of music discovered in between. Victor Esther Garcia took us on a journey that elapsed between Juchitán de Zaragoza in The Isthmus of Tehuantepec (Oaxaca) and Toniná (Chiapas) in the south of Mexico.',
  },
  {
    image: 'whetstone.jpg',
    title: 'A Week with the Sheep at Whetstone Farm',
    location: 'Amery, WI',
    text: 'The patter of tiny hooves, a rustle in the hay, gravel crunching underfoot. We lived among the kind folks and sheep at Whetstone Farm as some tended to their cornucopia of vegetables and others grazed the rolling pastures. ',
  },
]

const ArchivePreviewSection = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <ArchivePreviewSectionContainer>
      <SectionTitle>
        What We&rsquo;ve
        <br />
        Been Listening To
      </SectionTitle>
      {isMounted && (
        <Swiper
          spaceBetween={20}
          slidesPerView={1.3}
          breakpoints={{
            600: {
              slidesPerView: 2.4,
              spaceBetween: 100,
            },
          }}
          centeredSlides={true}
          loop={true}
          slideToClickedSlide={true}
          centeredSlidesBounds={true}>
          {archiveCards.map((c) => {
            return (
              <SwiperSlide key={c.image}>
                <ArchiveCardContainer>
                  <ArchiveCardImage
                    key={c.image}
                    style={{
                      backgroundImage: `url(/archive-cards/${c.image})`,
                    }}></ArchiveCardImage>
                  <ArchiveCardTitle>{c.title}</ArchiveCardTitle>
                  <ArchiveCardLocation>
                    <ArchiveCardLocationImage src="/location-icon.svg" />
                    {c.location}
                  </ArchiveCardLocation>
                  <ArchiveCardText>{c.text}</ArchiveCardText>
                </ArchiveCardContainer>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </ArchivePreviewSectionContainer>
  )
}

const ArchivePreviewSectionContainer = styled(Section)(mq({}))

const ArchiveCardContainer = styled.div(
  mq({
    textAlign: 'center',
  }),
)

const ArchiveCardImage = styled.div(
  mq({
    margin: '0 auto',
    width: [300, 275],
    height: [400, 400],
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }),
)

const ArchiveCardTitle = styled.div(
  typography.mediumSerif,
  mq({
    lineHeight: 0.9,
    maxWidth: 350,
    margin: '0 auto',
    marginTop: spaces.small,
  }),
)

const ArchiveCardLocation = styled.div(
  typography.tinyMono,
  mq({
    marginBottom: spaces.small,
  }),
)

const ArchiveCardLocationImage = styled.img(
  mq({
    display: 'inline-block',
    position: 'relative',
    top: 4,
    lineHeight: 0,
    paddingRight: 7,
  }),
)

const ArchiveCardText = styled.div(typography.tinyMono, mq({}))

/* Waitlist Section */

const WaitlistSection = () => {
  return (
    <WaitlistSectionContainer>
      <WaitlistSectionTitle>Batch Eight in Production</WaitlistSectionTitle>

      <SectionContent>
        <WaitlistSectionMainText>
          Oda speakers are produced in limited editions and made by hand — and
          have sold out six times. Sign up below to be the first to gain access
          to Batch Eight.
        </WaitlistSectionMainText>

        <WaitlistSectionButtonContainer>
          <WaitlistButton size="large" colorScheme="light" />
        </WaitlistSectionButtonContainer>
      </SectionContent>
    </WaitlistSectionContainer>
  )
}

const WaitlistSectionContainer = styled(Section)(
  mq({
    color: colors.lightBrown,
    backgroundColor: colors.darkBrown,
    textAlign: 'center',
    WebkitFontSmoothing: 'antialiased',
    borderBottom: 'none',
  }),
)

const WaitlistSectionTitle = styled.div(
  mq({
    textAlign: 'center',
    fontFamily: fonts.garamond.condensed,
    fontSize: ['64px', '48px'],
    lineHeight: 0.9,
    marginTop: -20,
  }),
)

const WaitlistSectionMainText = styled.div(
  typography.tinyMono,
  mq({
    width: '760px',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: spaces.medium,
    marginTop: spaces.small,
  }),
)

const WaitlistSectionButtonContainer = styled.div(mq({}))

const WaitlistSectionPostscript = styled.div(
  typography.tinyMono,
  mq({
    width: '760px',
    maxWidth: '100%',
    margin: '0 auto',
  }),
)

export default FrontMatter
