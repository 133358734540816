import React, { FC } from 'react'

import { styled, mq, colors } from '../../styles'
import { HowItFeelsSectionData } from '../../types'
import Title from '../Title'
import Section from '../Section'
import BaseCaption from '../Caption'
import VideoPlayer from '../VideoPlayer'

const HowOdaFeelsSection: FC<{ section: HowItFeelsSectionData }> = ({
  section,
}) => {
  return (
    <Container>
      <VideoContainer>
        <Player
          aspect={1.77777777777}
          id={'14dfbe9f517012d2e75814787c23acd7'}
          autoPlay
          muted
          loop
        />
      </VideoContainer>
    </Container>
  )
}

const Container = styled(Section)(
  mq({
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.mediumBrown,
    paddingBottom: [0, 0],
  }),
)

const VideoContainer = styled('div')({
  width: '100%',
})

const Player = styled(VideoPlayer)(
  mq({
    width: [1000, '100%'],
    margin: '0 auto',
    marginTop: '2em',
    pointerEvents: 'none',
    mixBlendMode: 'multiply',
  }),
)

const Caption = styled(BaseCaption)({
  marginBottom: 0,
})

export default HowOdaFeelsSection
