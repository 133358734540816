import React, {
  FC,
  MouseEventHandler,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

import MediaPlaceholder from './MediaPlaceholder'
import { colors, mq } from '../styles'
import Button from './Button'
import useCloudflareVideo from '../lib/useCloudflareVideo'

const useElementSize = <T extends HTMLElement>(ref: RefObject<T>) => {
  const [size, setSize] = useState({ width: 0, height: 0 })

  const updateSize = useCallback(() => {
    if (!ref.current) return
    const width = ref.current.clientWidth
    const height = ref.current.clientHeight
    if (width !== size.width || height !== size.height) {
      setSize({ width: width, height: height })
    }
  }, [])
  useLayoutEffect(updateSize, [])

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}

interface VideoPlayerProps {
  aspect: number
  id: string
  hidePlayButton?: boolean
  autoPlay?: Boolean
  muted?: boolean
  className?: string
  background?: string
  controls?: boolean
  loop?: boolean
  posterTime?: number
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  aspect,
  id,
  muted,
  loop,
  hidePlayButton,
  autoPlay,
  className,
  background,
  controls,
  posterTime,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null) as MutableRefObject<
    HTMLVideoElement
  >
  const controlsRef = useRef<HTMLDivElement>(null)
  const videoSize = useElementSize(controlsRef)

  const [isPlaying, setIsPlaying] = useState(false)
  const [isEnded, setIsEnded] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [isMuted, setIsMuted] = useState(!!muted)
  const [hasBeenPlayed, setHasBeenPlayed] = useState(false)

  const handleReady = useCallback(() => {
    setIsReady(true)
  }, [])

  const handlePlay = useCallback(() => {
    setHasBeenPlayed(true)
    setIsPlaying(true)
    setIsEnded(false)
  }, [])

  const handlePause = useCallback(() => {
    setIsPlaying(false)
  }, [])

  const handleEnd = useCallback(() => {
    setIsEnded(true)
  }, [])

  const handlePressTogglePlay: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (isPlaying) {
        setIsPlaying(false)
        videoRef.current?.pause()
      } else {
        setIsPlaying(true)
        videoRef.current?.play()
      }
    },
    [isPlaying],
  )

  const handlePressRestart = useCallback(() => {
    setIsEnded(false)
    videoRef.current?.fastSeek(0)
    videoRef.current?.play()
  }, [])

  const handlePressToggleMute: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setIsMuted((isMuted) => !isMuted)
    },
    [],
  )

  const [mouseIsOverControls, setMouseIsOverControls] = useState(false)
  const controlsMouseOverTimer = useRef<ReturnType<typeof setTimeout>>()
  const handleControlsMouseMove = useCallback(() => {
    if (controlsMouseOverTimer.current)
      clearTimeout(controlsMouseOverTimer.current)
    setMouseIsOverControls(true)
    controlsMouseOverTimer.current = setTimeout(
      () => setMouseIsOverControls(false),
      3000,
    )
  }, [])

  const handleControlsMouseOut = useCallback(() => {
    setMouseIsOverControls(false)
    if (controlsMouseOverTimer.current)
      clearTimeout(controlsMouseOverTimer.current)
  }, [])

  const controlsOpacity = !isPlaying ? 1 : mouseIsOverControls ? 1 : 0.00001

  const handleControlsClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      handlePressTogglePlay(e)
    },
    [handlePressTogglePlay],
  )

  const { posterUrl } = useCloudflareVideo(videoRef, id, posterTime || 0)

  return (
    <MediaPlaceholder
      background={background}
      className={className}
      aspect={aspect}>
      <Video
        ref={videoRef}
        width={`${videoSize.width}px`}
        height={`${videoSize.height}px`}
        autoPlay={!!autoPlay}
        preload={'none'}
        muted={isMuted}
        loop={loop}
        poster={posterUrl}
        onPause={handlePause}
        onPlay={handlePlay}
        onEnded={handleEnd}
        onCanPlayThrough={handleReady}
        src={id}
        controls={false}
        playsInline={true}
      />
      {controls && (
        <Controls
          style={{ opacity: controlsOpacity }}
          ref={controlsRef}
          onClick={handleControlsClick}
          onMouseMove={handleControlsMouseMove}
          onMouseOut={handleControlsMouseOut}>
          {isEnded ? (
            <RestartButton onClick={handlePressRestart} />
          ) : !hidePlayButton && hasBeenPlayed ? (
            <PlayPauseButton
              isPlaying={isPlaying}
              onClick={handlePressTogglePlay}
            />
          ) : (
            <InitialPlayButton onClick={handlePressTogglePlay}>
              Play the video
              <InitialPlayButtonImage
                src={'/video-player/icon_play_white.svg'}
              />
            </InitialPlayButton>
          )}
          {/* {isPlaying && (
          <MuteToggleButton isMuted={isMuted} onClick={handlePressToggleMute} />
        )} */}
        </Controls>
      )}
    </MediaPlaceholder>
  )
}

type ButtonProps = JSX.IntrinsicElements['button']

type BaseButtonProps = ButtonProps & {
  iconSrc: string
}

const RawButton = styled('button')(
  mq({
    maxWidth: ['auto', 100],
  }),
)

const ButtonImage = styled('img')({
  maxWidth: '100%',
})

const BaseButton: FC<BaseButtonProps> = ({ iconSrc, ...props }) => {
  return (
    <RawButton {...props}>
      <ButtonImage src={iconSrc} />
    </RawButton>
  )
}

const BasePlayPauseButton: FC<ButtonProps & { isPlaying: boolean }> = ({
  isPlaying,
  ...props
}) => {
  return isPlaying ? (
    <BaseButton iconSrc={'/video-player/icon_pause.svg'} {...props} />
  ) : (
    <BaseButton iconSrc={'/video-player/icon_play.svg'} {...props} />
  )
}

const BaseMuteToggleButton: FC<ButtonProps & { isMuted: boolean }> = ({
  isMuted,
  ...props
}) => {
  return isMuted ? (
    <BaseButton iconSrc={'/video-player/icon_sound_on.svg'} {...props} />
  ) : (
    <BaseButton iconSrc={'/video-player/icon_sound_off.svg'} {...props} />
  )
}

const BaseRestartButton: FC<ButtonProps> = (props) => {
  return <BaseButton iconSrc={'/video-player/icon_restart.svg'} {...props} />
}

const Video = styled.video({
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  ':focus': {
    outline: 0,
  },
})

const Controls = styled('div')({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  transition: 'opacity .5s',
})

const InitialPlayButton = styled(Button)(
  mq({
    fontSize: ['2em', '1.5em'],
    textTransform: 'uppercase',
    padding: ['1px 25px 4px', '3px 25px 6px'],
    maxWidth: [300, 'auto'],
    width: ['100%', 'auto'],
    margin: '0 auto',
    backgroundColor: colors.goldenBrown,
    border: '1px solid black',
  }),
)

const InitialPlayButtonImage = styled('img')(
  mq({
    width: '.85em',
    display: 'inline-block',
    position: 'relative',
    top: 2,
    marginLeft: 10,
  }),
)

const PlayPauseButton = styled(BasePlayPauseButton)(
  mq({
    position: 'absolute',
    bottom: '2em',
    left: '2em',
    pointerEvents: 'none',
    userSelect: 'none',
  }),
)

const MuteToggleButton = styled(BaseMuteToggleButton)(
  mq({
    maxWidth: ['auto', 40],
    position: 'absolute',
    bottom: ['2em', '1em'],
    left: ['2em', '1em'],
  }),
)

const RestartButton = styled(BaseRestartButton)(
  mq({
    position: 'absolute',
    bottom: '2em',
    left: '2em',
    pointerEvents: 'none',
    userSelect: 'none',
  }),
)

export default VideoPlayer
