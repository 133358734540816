import React, { ComponentType } from 'react'
import { mq, styled } from '../styles'

const breakModes = {
  Desktop: [true, false, false],
  TabletUp: [true, true, false],
  TabletDown: [false, true, true],
  Mobile: [false, false, true],
}

type BreakMode = keyof typeof breakModes

const breakModeKeys: BreakMode[] = (Object.keys(
  breakModes,
) as unknown) as BreakMode[]

const SpaceBreak = breakModeKeys.reduce((memo, k: BreakMode) => {
  const Space = styled.span(
    mq({
      display: breakModes[k].map((m) => (!m ? 'inline' : 'none')),
    }),
  )
  const Break = styled.br(
    mq({
      display: breakModes[k].map((m) => (m ? 'block' : 'none')),
    }),
  )
  memo[k] = () => {
    return (
      <>
        <Space>&nbsp;</Space>
        <Break />
      </>
    )
  }
  return memo
}, {} as { [K in BreakMode]: ComponentType })

export default SpaceBreak
