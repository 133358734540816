import React, { MutableRefObject, useEffect, useState } from 'react'
import Hls from 'hls.js'

const getCloudflarePlaylistUrl = (videoId: string) => {
  return `https://videodelivery.net/${videoId}/manifest/video.m3u8`
}
const getPosterUrl = (videoId: string, time: number, width: number) => {
  return `https://videodelivery.net/${videoId}/thumbnails/thumbnail.jpg?width=${Math.min(
    width,
    2000,
  )}&time=${time}s`
}

const useCloudflareVideo = (
  ref: MutableRefObject<HTMLVideoElement>,
  videoId: string,
  posterTime: number,
) => {
  const [isReady, setIsReady] = useState(false)
  const [posterUrl, setPosterUrl] = useState('')
  const videoSrc = getCloudflarePlaylistUrl(videoId)

  useEffect(() => {
    setPosterUrl(getPosterUrl(videoId, posterTime, window.innerWidth * 2))
  }, [])

  useEffect(() => {
    if (ref.current && Hls.isSupported()) {
      var hls = new Hls()
      hls.loadSource(videoSrc)
      hls.attachMedia(ref.current)
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        setIsReady(true)
      })
    } else if (
      ref.current &&
      ref.current.canPlayType('application/vnd.apple.mpegurl')
    ) {
      ref.current.src = videoSrc
      ref.current.addEventListener('loadedmetadata', function () {
        setIsReady(true)
      })
    }
  }, [])

  return { isReady, posterUrl }
}

export default useCloudflareVideo
