import React, { FC, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { mq, styled } from '../styles'
import { getSanitySrcSet } from './SanityFluidImage'
import { Global, css } from '@emotion/react'
import config from '../config'
import 'swiper/css/bundle'

export default () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return isMounted ? (
    <Swiper
      spaceBetween={25}
      slidesPerView={'auto'}
      centeredSlides={true}
      loop={true}
      breakpoints={{
        800: {
          slidesPerView: 'auto',
          spaceBetween: 100,
        },
      }}>
      <Global
        styles={css(
          mq({
            '.swiper-slide': {
              width: ['1000px', '80vw'],
            },
          }),
        )}
      />
      {config.sanityImages.slides.map((s, i) => {
        return (
          <SwiperSlide key={`${i}`}>
            <SingleSlide src={s} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  ) : null
}

const SingleSlide: FC<{ src: string }> = ({ src }) => {
  return (
    <Slide>
      <SlideImage
        src={src}
        srcSet={getSanitySrcSet(src, 1000)}
        sizes={'(max-width:1000px) 100vw, 1000px'}
      />
    </Slide>
  )
}

const Slide = styled('div')(
  mq({
    position: 'relative',
    paddingBottom: ['67.34%', '80%'],
    maxHeight: 400,
    width: '1000px',
    maxWidth: '100%',
    margin: '0 auto',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    cursor: 'pointer',
  }),
)

const SlideImage = styled.img(
  mq({
    display: 'block',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
)
