import { colors, mq, styled, typography } from '../styles'
import P from './base/P'

const Caption = styled(P)(
  typography.tinyMono,
  mq({
    marginBottom: ['2em', '1em'],
    marginTop: ['.75em', '.5em'],
    color: colors.goldenBrown,
    ':last-child': {
      marginBottom: 0,
    },
  }),
)

export default Caption
